import React from "react"
import Hero from "../Hero/Hero"
import Menu from "../NavMenu/Menu"
import Footer from "../Footer/Footer"

const PageLayout: React.FC<{
  children: React.ReactNode
  showHero?: boolean
  selectedMenu?: string
}> = ({ showHero, children, selectedMenu }) => {
  return (
    <>
      <Menu selectedMenu={selectedMenu} />
      {showHero && <Hero />}
      <main id="main">{children}</main>
      <Footer />
    </>
  )
}

export default PageLayout
