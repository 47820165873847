import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./style.css"

export const MenuItems = {
  HOME: "HOME",
  ABOUT: "ABOUT",
  PROJECTS: "PROJECTS",
  BLOGS: "BLOGS",
  CONTACT: "CONTACT",
}

const Menu: React.FC<{ isHomePage?: boolean; selectedMenu?: string }> = ({
  isHomePage,
  selectedMenu,
}) => {
  const [currentSelection, setCurrentSelection] = useState(MenuItems.HOME)
  const toggleMobileMenu = () => {
    const body = document.querySelector("body")
    body?.classList.toggle("mobile-nav-active")
  }

  const hideMobileMenu = () => {
    const body = document.querySelector("body")

    if (body && body.classList.contains("mobile-nav-active")) {
      body.classList.remove("mobile-nav-active")
    }
  }

  const handleMenuOutsideClick = (event: any) => {
    const menuContainer = document.querySelector("#header")
    const mobileMenu = document.querySelector(".mobile-nav-toggle")

    if (
      menuContainer !== null &&
      menuContainer !== event.target &&
      !mobileMenu?.contains(event.target) &&
      !menuContainer.contains(event.target)
    ) {
      hideMobileMenu()
    }
  }

  useEffect(() => {
    if (isHomePage && (!selectedMenu || selectedMenu.trim() === "")) {
      setCurrentSelection(MenuItems.HOME)
      return
    }

    if (selectedMenu && selectedMenu.trim() !== "") {
      setCurrentSelection(selectedMenu)
    }
  }, [isHomePage, selectedMenu])

  useEffect(() => {
    const mobileMenu = document.querySelector(".mobile-nav-toggle")

    if (mobileMenu) {
      mobileMenu.addEventListener("click", toggleMobileMenu)
    } else {
      console.log("Mobile Menu not found !!!")
    }

    document.addEventListener("click", handleMenuOutsideClick)

    return () => {
      if (mobileMenu) {
        mobileMenu.removeEventListener("click", toggleMobileMenu)
      } else {
        console.log("Mobile Menu not found !!!")
      }

      document.removeEventListener("click", handleMenuOutsideClick)
    }
  }, [toggleMobileMenu, handleMenuOutsideClick])

  return (
    <>
      <button type="button" className="mobile-nav-toggle d-xl-none">
        <i className="icofont-navigation-menu"></i>
      </button>
      <header id="header">
        <button
          id="mobile-menu-close"
          type="button"
          className="d-xl-none"
          onClick={hideMobileMenu}
        >
          <i className="icofont-close"></i>
        </button>
        <div className="d-flex flex-column">
          <div className="profile">
            <img
              src="/assets/debo-profile-003.jpg"
              alt="Debojit Roy Profile Photo"
              className="img-fluid rounded-circle"
            />
            <h1 className="text-light">
              <a href="index.html">Debojit Roy</a>
            </h1>
            <div className="social-links mt-3 text-center">
              <a
                href="https://www.linkedin.com/in/debojit-roy-6b294a25/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin"
                title="Debojit Roy LinkedIn Profile"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
              <a
                href="https://github.com/debojitroy/"
                target="_blank"
                rel="noopener noreferrer"
                className="github"
                title="Debojit Roy Github Profile"
              >
                <i className="bx bxl-github"></i>
              </a>
              <a
                href="https://medium.com/@debojity2k"
                target="_blank"
                rel="noopener noreferrer"
                className="medium"
                title="Debojit Roy Medium Profile"
              >
                <i className="bx bxl-medium"></i>
              </a>
              <a
                href="https://dev.to/debojitroy"
                target="_blank"
                rel="noopener noreferrer"
                className="dev-to"
                title="Debojit Roy Dev.to Profile"
              >
                <i className="bx bxl-dev-to"></i>
              </a>
            </div>
          </div>
          <nav className="nav-menu">
            <ul>
              <li
                className={currentSelection === MenuItems.HOME ? "active" : ""}
                onClick={() => {
                  setCurrentSelection(MenuItems.HOME)
                  hideMobileMenu()
                }}
              >
                <AnchorLink
                  to="/#hero-particles"
                  title="Debojit Roy Home"
                  className="stripped"
                  stripHash
                >
                  <i className="bx bx-home"></i> <span>Home</span>
                </AnchorLink>
              </li>
              <li
                className={currentSelection === MenuItems.ABOUT ? "active" : ""}
                onClick={() => {
                  setCurrentSelection(MenuItems.ABOUT)
                  hideMobileMenu()
                }}
              >
                <AnchorLink
                  to="/#about"
                  title="About Debojit Roy"
                  className="stripped"
                  stripHash
                >
                  <i className="bx bx-user"></i> <span>About</span>
                </AnchorLink>
              </li>
              <li
                className={
                  currentSelection === MenuItems.PROJECTS ? "active" : ""
                }
                onClick={() => {
                  setCurrentSelection(MenuItems.PROJECTS)
                  hideMobileMenu()
                }}
              >
                <Link to="/projects/">
                  <i className="bx bx-book-content"></i> Projects
                </Link>
              </li>
              <li
                className={currentSelection === MenuItems.BLOGS ? "active" : ""}
                onClick={() => {
                  setCurrentSelection(MenuItems.BLOGS)
                  hideMobileMenu()
                }}
              >
                <Link to="/blogs/">
                  <i className="bx bx-book-alt"></i> Blogs
                </Link>
              </li>
              <li
                className={
                  currentSelection === MenuItems.CONTACT ? "active" : ""
                }
                onClick={() => {
                  setCurrentSelection(MenuItems.CONTACT)
                  hideMobileMenu()
                }}
              >
                <AnchorLink
                  to="/#contact"
                  title="Get in touch"
                  className="stripped"
                  stripHash
                >
                  <i className="bx bx-envelope"></i> Contact
                </AnchorLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Menu
