import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import isNil from "lodash/isNil"
import SchemaOrg from "./SchemaOrg"
import { generateUrl } from "../../utils/socialUtils";

export interface SEOProps {
  title?: string
  description?: string
  imageUrl?: string
  slug?: string
  isBlogPost?: boolean
  datePublished?: Date
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  imageUrl,
  slug,
  isBlogPost,
  datePublished,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author {
              name
              minibio
              email
              photo
            }
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppID
            }
          }
        }
      }
    `
  )

  const seoTitle = !isNil(title) ? title : site.siteMetadata.title
  const seoDescription = !isNil(description)
    ? description
    : site.siteMetadata.description
  const seoImageUrl = !isNil(imageUrl)
    ? generateUrl(site.siteMetadata.siteUrl, imageUrl)
    : generateUrl(site.siteMetadata.siteUrl, site.siteMetadata.image)
  const seoCanonicalUrl = !isNil(slug)
    ? generateUrl(site.siteMetadata.siteUrl, slug)
    : site.siteMetadata.siteUrl
  const fbAppID = site.siteMetadata.social.fbAppID
  const twitterId = site.siteMetadata.social.twitterId

  return (
    <React.Fragment>
      <Helmet>
        {/* General tags */}
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="image" content={seoImageUrl} />
        <link rel="canonical" href={seoCanonicalUrl} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={seoCanonicalUrl} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content={seoImageUrl} />
        <meta property="fb:app_id" content={fbAppID} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterId} />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content={seoImageUrl} />
      </Helmet>
      <SchemaOrg
        author={site.siteMetadata.author}
        siteUrl={site.siteMetadata.siteUrl}
        datePublished={datePublished}
        defaultTitle={site.siteMetadata.title}
        description={seoDescription}
        image={seoImageUrl}
        isBlogPost={isBlogPost!}
        organization={site.siteMetadata.organization}
        title={seoTitle}
        url={seoCanonicalUrl}
      />
    </React.Fragment>
  )
}

export default SEO
