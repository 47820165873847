import React from "react"
import "./style.css"

const Footer: React.FC<{}> = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Debojit Roy</span>
          </strong>
        </div>
        <div className="credits">
          Built using{" "}
          <a
            href="https://gatsbyjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gatsby
          </a>{" "}
          <i className="bx bxs-heart"></i>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
