export const generateUrl = (host: string, slug: string) => {
  if (host.endsWith("/")) {
    return `${host}${slug}`
  } else if (slug.startsWith("/")) {
    return `${host}${slug}`
  } else {
    return `${host}/${slug}`
  }
}

export const generateFacebookShareUrl = (host: string, slug: string) => {
  const canonicalUrl = generateUrl(host, slug)

  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    canonicalUrl
  )}`
}

export const generateTwitterShareUrl = (
  host: string,
  slug: string,
  title: string
) => {
  const canonicalUrl = generateUrl(host, slug)

  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    canonicalUrl
  )}&text=${encodeURIComponent(title)}`
}

export const generateLinkedInShareUrl = (
  host: string,
  slug: string,
  title: string
) => {
  const canonicalUrl = generateUrl(host, slug)

  return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    canonicalUrl
  )}&title=${encodeURIComponent(title)}`
}
